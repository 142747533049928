<template>
  <div class="error">
    <div class="error-info">
      <div class="tag">Error 404</div>
      <h1>Whoops!</h1>
      <p class="error-msg">That page does not exist on<br />our website. GG go next?</p>
      <router-link to="/" class="button return-button">
        <span>Back to home</span>
        <i class="far fa-long-arrow-left"></i>
      </router-link>
      
    </div>
    <div class="error-illustration">
      <div>
        <img src="@/assets/undraw-not-found.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - var(--navbar-height) - var(--footer-height));

  &-illustration {
    margin-left: 6rem;

    img {
      max-height: 300px;
    }
  }

  &-info {
    .tag {
      display: inline-block;
      padding: 6px 1.5rem;
      border: 2px solid #817F98;
      color: #817F98;
      border-radius: 100px;
      margin-bottom: 1rem;
    }

    h1 {
      margin-bottom: 1rem;
      font-size: 48px;
    }

    .error-msg {
      color: #817F98;
      font-size: 20px;
    }

    .return-button {
      position: relative;
      width: 180px;
      margin-top: 2rem;
      border-radius: 1000px;
      text-align: left;
        
      i {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 1.5rem;
        height: 100%;
        color: #fff;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .error .error-illustration img {
    max-height: 200px;
  }
}

@media screen and (max-width: 800px) {
  .error {
    flex-direction: column-reverse;
    
    .error-illustration {
      margin-left: 0;
      margin-bottom: 3rem;

      img {
        max-height: 150px;
      }
    }

    .error-info {
      text-align: center;
    }
  }
}
</style>